<template>
  <div>
    <v-row class="table-actions-row">
      <v-col sm="4" cols="12" class="pl-0">
        <breadcrumbs :items="crumbs" />
      </v-col>
      <v-col sm="8" class="d-flex justify-end align-center" />
    </v-row>
    <localization :language="'ru-RU'">
      <intl :locale="'ru'">
        <Grid
          ref="grid"
          :filterable="true"
          :filter="filter"
          :pageable="gridPageable"
          :take="take"
          :skip="skip"
          :page-size="pageSize"
          :total="total"
          :data-items="dataResult"
          :columns="columns"
          :expand-field="'expanded'"
          @datastatechange="dataStateChange"
          @expandchange="expandChange"
        >
          <template v-slot:actions="{ props: { dataItem } }">
            <td>
              <div class="actionsColumn">
                <CButton
                  @click="openUpdateTravelModalHandle(dataItem)"
                  class="actions-btn btn-square btn-info"
                />
              </div>
            </td>
          </template>
        </Grid>
      </intl>
    </localization>
    <travel-update-modal
      :current-item="currentItem"
      :show-dialog="showEntityModal"
      @close="closeUpdateTravelModalHandle"
      @stored="entityStored($event)"
    />
  </div>
</template>

<script>
import { Grid } from '@progress/kendo-vue-grid'
import {
  IntlProvider,
  LocalizationProvider,
} from '@progress/kendo-vue-intl'
import TableMixin from "@/views/grid_elements/mixins/TableMixin";
import breadcrumbs from "@/views/components/breadcrumbs.vue";
import '@/views/grid_elements/translations'
import api from "@/api";

export default {
  name: 'Travels',
  components: {
    breadcrumbs,
    localization: LocalizationProvider,
    intl: IntlProvider,
    Grid: Grid,
    TravelUpdateModal: () => import('./modals/TravelUpdateModal.vue'),
  },
  mixins: [TableMixin],
  data() {
    return {
      gridPageable: { pageSizes: true },
      columns: [
        {
          field: 'id',
          title: 'Действия',
          cell: 'actions',
          filterable: false,
          width: '100px',
        },
        {
          field: 'title',
          title: 'Название',
          filter: 'text',
          type: 'text',
        },
        {
          field: 'url',
          title: 'Символьный код',
          filter: 'text',
          type: 'text',
        },
      ],
    }
  },
  methods: {
    fetchData: api.travels.visits,
    openUpdateTravelModalHandle(item) {
      this.setCurrentItem(item)
      this.showEntityModal = true
    },
    closeUpdateTravelModalHandle() {
      this.showEntityModal = false
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>

</style>
